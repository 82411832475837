import React, { useState } from 'react'

import Spinner from 'components/spinner/Spinner';
import "./LeaderboardAllPlayerStats.scss"

import PlayerIcon from 'components/icons/PlayerIcon';
import ScoreIcon from 'components/icons/ScoreIcon';
import TimeIcon from 'components/icons/TimeIcon';
import JetIcon from 'components/icons/JetIcon';
import TankIcon from 'components/icons/TankIcon';
import SkullIcon from 'components/icons/SkullIcon';

import { useNavigate } from 'react-router-dom';

interface rankingData {
    name: string;
    totalpoints: number;
    totalflighttime: number;
    a2akills: number;
    a2gkills: number;
    deaths: number;
}

type Props = {
    setShowErrorBox: React.Dispatch<React.SetStateAction<number>>
    setErrorText: React.Dispatch<React.SetStateAction<string>>
    isLoading: boolean
    data: any
    searchTerm: string
}

const LeaderboardAllPlayerStats = (props: Props) => {
    const navigate = useNavigate();

    const [sortBy, setSortBy] = useState<keyof rankingData>('totalpoints');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    if (props.isLoading) {
        return <Spinner />;
    }


    const handleSort = (field: keyof rankingData) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortDirection('asc');
            setSortBy(field);
        }
    };

    function mapToObject(arr: any): Array<rankingData> {
        return arr.map((innerArr: any, index: any) => {
            return {
                name: innerArr[0],
                totalflighttime: innerArr[1]["total_flight_time"],
                totalpoints: innerArr[1]["total_points"],
                a2akills: innerArr[1]["total_a2akills"],
                a2gkills: innerArr[1]["total_a2gkills"],
                deaths: innerArr[1]["total_deaths"]
            };
        });
    }

    function secondsToHms(d: number) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + "h " : "";
        var mDisplay = m > 0 ? m + "m " : "";
        var sDisplay = s > 0 ? s + "s " : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const handleRedirect = (name: string) => {
        localStorage.setItem("searchTerm", name)
        localStorage.setItem("playerCardVisible", "True");
        navigate('/pilot');
    }


    const getSortedData = () => {
        if (props.data) {
            try {
                const sortedData = mapToObject(props.data).sort((a: rankingData, b: rankingData) => {
                    if (sortDirection === 'asc') {
                        return a[sortBy] > b[sortBy] ? 1 : -1;
                    } else {
                        return a[sortBy] < b[sortBy] ? 1 : -1;
                    }
                })

                return (sortedData.filter((val: rankingData) => {
                    if (props.searchTerm === '') {
                        return val
                    } else if (
                        val.name.toLowerCase().includes(props.searchTerm.toLowerCase())
                    ) {
                        return val
                    } else {
                        return undefined
                    }
                }).map((item: rankingData, index: number) => (
                    <tbody key={"body" + index}>
                        <tr onClick={() => handleRedirect(item.name)} key={"tr" + index} className={"trow rank-" + index}>
                            <td key={"name" + index} className={"tableitem_name"}><p>{item.name}</p></td>
                            <td key={"totalpoints" + index} className={"tableitem_points"}><p>{item.totalpoints}</p></td>
                            <td key={"flighttime" + index} className={"tableitem_flighttime"}><p>{secondsToHms(item.totalflighttime)}</p></td>
                            <td key={"a2akills" + index} className={"tableitem_a2akills"}><p>{item.a2akills}</p></td>
                            <td key={"a2gkills" + index} className={"tableitem_a2gkills"}><p>{item.a2gkills}</p></td>
                            <td key={"deaths" + index} className={"tableitem_deaths"}><p>{item.deaths}</p></td>
                        </tr>
                    </tbody>
                )
                ))
            } catch (err: any) {
                props.setShowErrorBox(1)
                props.setErrorText(err.message)
            }

        }
    }


    return (
        <div className="allplayerstatscontent">


            <div className="allplayerstats">

                <table className="blur smoothboxshadow">
                    <tbody>
                        <tr >

                            <th onClick={() => handleSort('name')}><PlayerIcon /> Name {sortBy === 'name' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('totalpoints')}><ScoreIcon /> Total Points {sortBy === 'totalpoints' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('totalflighttime')}><TimeIcon /> Flight Time {sortBy === 'totalflighttime' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('a2akills')}><JetIcon /> A2A Kills {sortBy === 'a2akills' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('a2gkills')}><TankIcon /> A2G Kills {sortBy === 'a2gkills' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('deaths')}><SkullIcon /> Deaths {sortBy === 'deaths' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>

                        </tr>
                    </tbody>
                    {getSortedData()}
                </table>
            </div>
        </div>
    );
};

export default LeaderboardAllPlayerStats