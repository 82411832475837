import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion'
import Hangar from './pages/Hangar/Hangar';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import Pilot from './pages/Pilot/Pilot';
import { Navigate } from 'react-router-dom';

type Props = {}

const AnimatedRoutes = (props: Props) => {
    const location = useLocation();
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Hangar />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/pilot/" element={<Pilot />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes