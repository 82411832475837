import React, { useState, useEffect, useCallback } from 'react'
import "./Pilot.scss"
import { motion } from "framer-motion"
import TextField from '@mui/material/TextField';
import ErrorBox from 'components/error/ErrorBox';
import Spinner from 'components/spinner/Spinner';
import Button from '@mui/material/Button';
import axios, { AxiosError } from "axios";
import { useQuery } from 'react-query'
import PlayerCard from './PlayerCard/PlayerCard';
import { config } from "env_constants";
import { getAnalytics, logEvent } from "firebase/analytics";

import playerJson from "../../assets/player.json"

type Props = {}

const Pilot = (props: Props) => {
    const [searchTerm, setSearchTerm] = useState(localStorage.getItem("searchTerm") || "")
    const [showErrorBox, setShowErrorBox] = useState(0)
    const [errorText, setErrorText] = useState("")
    const [playerCardVisible, setPlayerCardVisible] = useState(true)
    const analytics = getAnalytics();


    const onFormSubmit = (e: any) => {
        e.preventDefault();
    }

    const backendURL = config.url.API_URL

    const isLoading_player = false;
    const data = playerJson;

    // const { isLoading: isLoading_player, data, refetch } = useQuery<any, AxiosError>('playerstats', async () => {
    //     try {
    //         const response = await axios.get(backendURL + "/player/" + encodeURIComponent(searchTerm));
    //         localStorage.setItem("playerCardVisible", "True");
    //         setShowErrorBox(0);
    //         setPlayerCardVisible(true);
    //         localStorage.setItem("searchTerm", searchTerm)
    //         return response.data["player"];
    //     } catch (err: any) {
    //         setShowErrorBox(1);
    //         setErrorText(err.message);
    //         logEvent(analytics, 'axios_error_pilot', { errorText: errorText });
    //         if (err.response.status === 404) {
    //             setErrorText("Player not found");
    //         }
    //         setPlayerCardVisible(false);
    //         throw new Error(err);
    //     }
    // }, { enabled: false, retryOnMount: false, retry: false })

    // const handleSearchClick = useCallback(() => {
    //     refetch();

    // }, [refetch]);

    // useEffect(() => { //initial load, if there is a search term and player card is visible, refetch
    //     if (localStorage.getItem("searchTerm") !== null && localStorage.getItem("playerCardVisible") !== null) {
    //         if (localStorage.getItem("searchTerm") !== "" && localStorage.getItem("playerCardVisible") !== "False") {
    //             refetch();
    //         }
    //     }
    // }, [refetch]);


    // const escFunction = useCallback((event: any) => {
    //     if (event.key === "Escape") {
    //         handleReturnClick()
    //     }
    //     if (event.key === "Enter") {
    //         handleSearchClick()
    //     }
    // }, [handleSearchClick]);

    // useEffect(() => {
    //     document.addEventListener("keydown", escFunction, false);
    //     document.addEventListener("keydown", escFunction, false);

    //     return () => {
    //         document.removeEventListener("keydown", escFunction, false);
    //     };
    // }, [escFunction]);

    // useEffect(() => {
    //     localStorage.setItem("searchTerm", searchTerm)
    // }, [searchTerm])

    // const handleReturnClick = () => {
    //     localStorage.setItem("playerCardVisible", "False")
    //     setPlayerCardVisible(false)
    // }


    const getResults = () => {
        if (playerCardVisible === true) {

            
                if (data) {
                    return (
                        <>
                            <PlayerCard data={data} />
                            {/* <div className="returnbutton" ><Button onClick={handleReturnClick}>Return</Button></div> */}
                        </>
                    )

                }
            
        }
    }

    // const getSearchForm = () => {
    //     if (playerCardVisible === false) {
    //         return (
    //             <div className="searchfield blur smoothboxshadow">
    //                 <form onSubmit={onFormSubmit}>
    //                     <TextField onChange={(e) => { setSearchTerm(e.target.value) }} id="standard-basic" value={searchTerm} label="Name" variant="standard" />
    //                     {searchTerm === "" ? <Button variant="outlined" disabled>Search</Button> : <Button type="submit" onClick={handleSearchClick} variant="outlined">Search</Button>}
    //                     {isLoading_player ? <Spinner /> : <></>}
    //                 </form>
    //             </div>
    //         )
    //     }
    // }

    return (
        <motion.div
            className='pilot'
            initial={{ x: -window.innerWidth }}
            animate={{ x: 0, transition: { duration: 0.1 } }}
            exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}>

            {/* {getSearchForm()} */}

            {getResults()}

            {<ErrorBox errorText="Server currently unavailable."></ErrorBox>}


        </motion.div>
    )
}

export default Pilot