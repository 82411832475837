import React from 'react'
import "./Icon.scss"

const icon = require("resources/icons/tank.png")

type Props = {}

const TankIcon = (props: Props) => {
  return (
    <img src={icon} className="icon" height="20px" width="20px" alt="tank icon"></img>
  )
}

export default TankIcon