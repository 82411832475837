import Navbar from "./components/navbar/Navbar"
import Background from './components/background/Background';
import AnimatedRoutes from './AnimatedRoutes';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import './App.scss';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#f50057',
        },
        text: {
            primary: 'rgba(255,255,255,0.87)',
            secondary: 'rgba(255,255,255,0.87)',
            disabled: 'rgba(255,255,255,0.87)',
        },
    }
});

const queryClient = new QueryClient()

export default function App() {

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Navbar />
                <Background />
                <QueryClientProvider client={queryClient}>
                    <div className="container">
                        <AnimatedRoutes />
                    </div>
                </QueryClientProvider>
            </ThemeProvider>
        </div >
    );
}

