import React from 'react'
import "./Icon.scss"

const icon = require("resources/icons/score.png")

type Props = {}

const ScoreIcon = (props: Props) => {
  return (
    <img src={icon} className="icon" height="20px" width="20px" alt="score icon"></img>
  )
}

export default ScoreIcon