//map aircraft to the correct name
export const aircraftTranslation = (aircraft: string): string => {
    const aircrafts: { [key: string]: string } = {
        "FA-18C_hornet": "FA-18C Hornet",
        "Hercules": "C-130 Hercules",
        "F-16C_50": "F-16",
        "AJS37": "AJS37 Viggen",
        "AH-64D_BLK_II": "AH-64D Apache",
        "AH-64D_BLK_II Copilot": "AH-64D Apache Copilot",
        "UH-1H": "UH-1H Huey",
        "UH-60L": "UH-60L Blackhawk",
        "Ka-50_3": "KA-50 Black Shark",
        "F-14A-135-GR": "F-14A Tomcat",
        "F-14B": "F-14B Tomcat",

    }
    if (!aircrafts[aircraft]) {
        return aircraft;
    }

    return aircrafts[aircraft];
}