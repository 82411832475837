import React from 'react'
import "./Icon.scss"

const icon = require("resources/icons/time.png")

type Props = {}

const TimeIcon = (props: Props) => {
  return (
    <img src={icon} className="icon" height="20px" width="20px" alt="time icon"></img>
  )
}

export default TimeIcon