import React, { useState, useEffect } from 'react'
import "./PlayerCard.scss"
import Tilt from 'react-parallax-tilt';

import Spinner from 'components/spinner/Spinner';

import { aircraftTranslation } from './aircraftTranslation';

import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { ExpandMore } from '@mui/icons-material';

import { config } from "env_constants";
import axios, { AxiosError } from "axios";
import { useQuery } from 'react-query'

import playerAirplaneStatsF15Json from "../../../assets/playerairplane/playerairplanestats_f15wso.json"
import playerAirplaneListJson from "../../../assets/playerairplane/playerairplanelist.json"


const sha1 = require('sha1');

const avatars = [
    require("resources/avatars/0.webp"),
    require("resources/avatars/1.webp"),
    require("resources/avatars/2.webp"),
    require("resources/avatars/3.webp"),
    require("resources/avatars/4.webp"),
    require("resources/avatars/5.webp"),
    require("resources/avatars/6.webp"),
    require("resources/avatars/7.webp"),
    require("resources/avatars/8.webp"),
    require("resources/avatars/9.webp"),
]

const getAircraft: any = (name: string) => {

    switch (name) {
        case "":
            return null
        case "F-14A-135-GR":
            name = "F-14B"
    }

    try {
        return require("resources/aircraft/" + name + ".webp")
    }
    catch (e) {
        return null
    }
}



const customAvatars: any = {
    "a1cdcd50e40f4304df73a81ea96d587b": require("resources/avatars/custom/real_goose.webp"),
}


function reduceString(str: string) {
    // Hash the string using SHA-1
    const hash = sha1(str);
    // Convert the hash to a number by taking the first 10 characters and converting them to an integer
    const hashNum = parseInt(hash.substring(0, 10), 16);
    // Take the remainder when dividing by 10 to get a number between 0-9
    const reducedNum = hashNum % 10;
    // console.log("reduced: " + reducedNum)
    return reducedNum
}

function secondsToHms(d: number) {
    if (d === 0) {
        return "0s"
    }
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + "h " : "";
    var mDisplay = m > 0 ? m + "m " : "";
    var sDisplay = s > 0 ? s + "s " : "";
    return hDisplay + mDisplay + sDisplay;
}

const getAvatarImage = (ucid: string) => {

    return avatars[0]
}



const PlayerCard = (props: any) => {
    const [selectedAircraft, setSelectedAircraft] = useState("");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (name: any) => {
        setSelectedAircraft(name)
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const backendURL = config.url.API_URL

    const isLoading_aircraftlist = false;
    const aircraftlistdata = playerAirplaneListJson["airplanes"]

    // const { isLoading: isLoading_aircraftlist, data: aircraftlistdata } = useQuery<any, AxiosError>('aircraftlist', async () => {
    //     try {
    //         const response = await axios.get(backendURL + "/playerairplanelist/" + encodeURIComponent(localStorage.getItem("searchTerm") || ""));
    //         return response.data["airplanes"];
    //     } catch (err: any) {
    //         console.log(err.response.data)
    //         throw err.response.data
    //     }
    // }, {
    //     retry: false
    // })

    const isLoading_selectedAircraft = false;
    const selectedAircraft_data = playerAirplaneStatsF15Json["airplane_stats"]

    // const { isLoading: isLoading_selectedAircraft, data: selectedAircraft_data, refetch: refetch_selectedAircraft } = useQuery<any, AxiosError>('selectedaircraft', async () => {
    //     try {
    //         const response = await axios.get(backendURL + "/playerairplanestats/" + props.data.name + "/" + selectedAircraft);
    //         return response.data["airplane_stats"];
    //     } catch (err: any) {
    //         console.log(err.response.data)
    //         throw err.response.data
    //     }
    // }, {
    //     enabled: false, refetchOnMount: false,
    //     refetchOnWindowFocus: false,
    //     retry: false
    // })

    // useEffect(() => {
    //     if (aircraftlistdata) {
    //         if (aircraftlistdata.length > 0) {
    //             setSelectedAircraft(aircraftlistdata[0].name);
    //         } else {
    //             setSelectedAircraft("none");
    //         }
    //     }
    // }, [isLoading_aircraftlist, aircraftlistdata])

    // useEffect(() => {
    //     if (selectedAircraft !== "") {
    //         if (selectedAircraft !== "none") {
    //             refetch_selectedAircraft();
    //         }
    //     }

    // }, [selectedAircraft, refetch_selectedAircraft]);

    let joinDate = new Date(props.data.join_date * 1000);
    let lastJoin = new Date(props.data.last_join * 1000);

    let joinDateFormatted = joinDate.getDate() + "." + (joinDate.getMonth() + 1) + "." + joinDate.getFullYear();
    let lastJoinFormatted = lastJoin.getDate() + "." + (lastJoin.getMonth() + 1) + "." + lastJoin.getFullYear();

    const getAircraftImage = (aircraftName: string) => {
        let aircraft = getAircraft(aircraftName)
        if (aircraft) {
            return (
                <>
                    <img loading="lazy" className="aircraft_img" alt="player aircraft" src={aircraft} />
                </>
            )
        }
    }

    const getMenuItemsAircraft = () => {
        let items: any = []
        if (isLoading_aircraftlist) {
            return (
                <MenuItem onClick={handleClose} key="1" value="loading">Loading...</MenuItem>
            )
        }
        else if (aircraftlistdata) {
            if (aircraftlistdata.length === 0) {
                return <MenuItem onClick={handleClose} key="1" value="empty">No items to display</MenuItem>;
            }
            for (let i = 0; i < aircraftlistdata.length; i++) {
                items.push(
                    <MenuItem onClick={() => handleMenuItemClick(aircraftlistdata[i].name)} key={i} value={aircraftlistdata[i].name}>{aircraftTranslation(aircraftlistdata[i].name)}</MenuItem>
                )
            }
            return items
        }
    }

    return (
        <div className="playercard smoothboxshadow">
            <div className="avatar">
                <Tilt className="tilt" tiltMaxAngleX={5} tiltMaxAngleY={5} >
                    <img loading="lazy" className="avatarimg" alt="player avatar" src={getAvatarImage(props.data.ucid)} />
                </Tilt>
                <div className="playerinfo">
                    <h1>Sei-Tech</h1>
                    <div className="playerinfostats">
                        <p>Flight time: 59h 13m 50s</p>
                        <p>First Join: 03.02.2022</p>
                        <p>Last Join: 17.01.2024 </p>
                    </div>
                </div>
            </div>
            <div className="stats">

                <div className="datadiv">
                    <div className="playerstats">
                        <Tilt className="tilt playerstatsitem card" tiltMaxAngleX={5} tiltMaxAngleY={5} >
                            <div>
                                <h1 className="statsheader">Points</h1>
                                <p className="statsvalue">1008</p>
                            </div>
                        </Tilt>
                        <Tilt className="tilt playerstatsitem card" tiltMaxAngleX={5} tiltMaxAngleY={5} >
                            <div>
                                <h1 className="statsheader">A2A Kills</h1>
                                <p className="statsvalue">70</p>
                            </div>
                        </Tilt>
                        <Tilt className="tilt playerstatsitem card" tiltMaxAngleX={5} tiltMaxAngleY={5} >
                            <div>
                                <h1 className="statsheader">A2G Kills</h1>
                                <p className="statsvalue">424</p>
                            </div>
                        </Tilt>
                    </div>
                </div>

                {(isLoading_selectedAircraft || !selectedAircraft_data?.name) ? <Spinner /> :

                    <div className="datadiv">
                        <Tilt className="tilt glow-on-hover aircraft playerstatsitem card" tiltMaxAngleX={1} tiltMaxAngleY={1}>
                            {getAircraftImage(selectedAircraft_data.name)}
                            <div className="aircraft_content">

                                <div className="aircraft_name">
                                    <h1>{aircraftTranslation(selectedAircraft_data.name)}</h1>
                                    <p>Time flown: {secondsToHms(selectedAircraft_data.time)}</p>
                                </div>
                                <div className="switch_aicraft_button">
                                    <Tooltip title="Change aircraft shown (ordered by time flown)" placement="right">

                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <ExpandMore />
                                        </Button>
                                    </Tooltip>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {getMenuItemsAircraft()}
                                    </Menu>

                                </div>
                                <div className="aircraft_info">
                                    <div className="aircraft_stats">
                                        <h2 className="statname">Air to Air Kills: <p className="statvalue">34</p></h2>
                                        <h2 className="statname">Ground unit Kills: <p className="statvalue">41</p></h2>
                                        <h2 className="statname">Ship Kills: <p className="statvalue">8</p></h2>
                                        <h2 className="statname">Buildings Destroyed: <p className="statvalue">17</p></h2>
                                        <h2 className="statname">Helicopter Kills: <p className="statvalue">22</p></h2>
                                    </div>
                                </div>
                            </div>
                        </Tilt>
                    </div>
                }
            </div >

        </div >

    )
}

export default PlayerCard