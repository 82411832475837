import React, { useState } from 'react'

import Spinner from 'components/spinner/Spinner';
import "./LeaderboardTotalPointsRanking.scss"

import PlayerIcon from 'components/icons/PlayerIcon';
import TrophyIcon from 'components/icons/TrophyIcon';
import ScoreIcon from 'components/icons/ScoreIcon';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useNavigate } from 'react-router-dom';

interface rankingData {
    rank: number;
    name: string;
    totalpoints: number;
    award: any
}

type Props = {
    setShowErrorBox: React.Dispatch<React.SetStateAction<number>>
    setErrorText: React.Dispatch<React.SetStateAction<string>>
    isLoading: boolean
    searchTerm: string
    data: any
}

const LeaderboardTotalPointsRanking = (props: Props) => {
    const navigate = useNavigate();

    const [sortBy, setSortBy] = useState<keyof rankingData>('rank');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const isMobile = useMediaQuery('(max-aspect-ratio: 1/1)');


    function mapToObject(arr: Array<[string, number]>): Array<rankingData> {
        return arr.map((innerArr, index) => {
            return {
                rank: index + 1,
                name: innerArr[0],
                totalpoints: innerArr[1],
                award: getAward(index)
            };
        });
    }



    if (props.isLoading) {
        return <Spinner />;
    }


    const handleSort = (field: keyof rankingData) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortDirection('asc');
            setSortBy(field);
        }
    };

    const imagesizes = [
        { width: "250px", height: "250px" },
        { width: "200px", height: "200px" },
        { width: "200px", height: "200px" },
        { width: "200px", height: "200px" },
    ]

    const getAward = (index: number) => {
        if (index <= 3) {
            const picture = require("resources/ranks/flighttime/" + index + ".webp")
            return (
                <img src={picture} width={imagesizes[index].width} height={imagesizes[index].height} alt={"award for rank " + index} className={"rank" + index}></img>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const handleRedirect = (name: string) => {
        localStorage.setItem("searchTerm", name)
        localStorage.setItem("playerCardVisible", "True");
        navigate('/pilot');
    }


    const getSortedData = () => {
        if (props.data) {
            try {
                const sortedData = mapToObject(props.data).sort((a: rankingData, b: rankingData) => {
                    if (sortDirection === 'asc') {
                        return a[sortBy] > b[sortBy] ? 1 : -1;
                    } else {
                        return a[sortBy] < b[sortBy] ? 1 : -1;
                    }
                })

                return (sortedData.filter(val => {
                    if (props.searchTerm === '') {
                        return val
                    } else if (val.name.toLowerCase().includes(props.searchTerm.toLowerCase())) {
                        return val
                    } else {
                        return undefined
                    }
                }).map((item: any, index) => (
                    <tbody key={"body" + index}>
                        <tr onClick={() => handleRedirect(item.name)} key={"tr" + index} className={"trow rank-" + index}>
                            <td key={"rank" + index} className={"tableitem_rank rank" + item.rank}>
                                <div className={"rankdiv"}>
                                    <div className={"rankdivshape rank" + item.rank}></div>
                                    <p className={"rank" + item.rank}>{item.rank}</p>
                                </div>
                            </td>
                            <td key={"name" + index} className={"tableitem_name rank" + item.rank}>
                                <p>{item.name}</p>
                            </td>
                            <td key={"points" + index} className={"tableitem_points rank" + item.rank}>
                                <p>{item.totalpoints}</p>
                            </td>
                            {isMobile ? <></> : <td key={"award" + index} className={"tableitem_award rank" + item.rank}>{item.award}</td>}
                        </tr>
                    </tbody>
                )));
            } catch (err: any) {
                props.setShowErrorBox(1)
                console.log(err.message)
                props.setErrorText(err.message)
            }

        }
    }


    return (
        <div className="pointsrankingscontent">


            <div className="pointsrankingstablecontainer">

                <table className="blur smoothboxshadow">
                    <tbody>
                        <tr >
                            <th onClick={() => handleSort('rank')}><TrophyIcon /> Rank {sortBy === 'rank' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('name')}><PlayerIcon /> Name {sortBy === 'name' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('totalpoints')}><ScoreIcon /> Total Points {sortBy === 'totalpoints' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            {isMobile ? <></> : <th onClick={() => handleSort('award')}></th>}
                        </tr>
                    </tbody>
                    {getSortedData()}
                </table>
            </div>
        </div>
    );
};

export default LeaderboardTotalPointsRanking