import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";


const firebaseConfig = {

    apiKey: "AIzaSyAASxtWJOhUh6sIJht3OPzgOmixLHZNTlI",

    authDomain: "dcsstats-899a0.firebaseapp.com",

    projectId: "dcsstats-899a0",

    storageBucket: "dcsstats-899a0.appspot.com",

    messagingSenderId: "736743992374",

    appId: "1:736743992374:web:96ac73ab48332c32b63c97",

    measurementId: "G-FQJFBPCY6P"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
logEvent(analytics, 'loaded_main_page');


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);

