import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";
import { useScrollYPosition } from "react-use-scroll-position";
import useMediaQuery from "@mui/material/useMediaQuery";
// import Background from "components/background/Background";

const logo = require("resources/viking.webp");
const logo_leaderboard = require("resources/icon_leaderboard.webp");
const logo_hangar = require("resources/icon_hangar.webp");
const logo_pilot = require("resources/icon_pilot.webp");
const patreon = require("resources/patreon.png");

type Props = {};

const Navbar = (props: Props) => {
    const scrollY = useScrollYPosition();
    const isScrolled = () => {
        if (scrollY > 50) {
            return "scrolled";
        } else {
            return "";
        }
    };

    const isMobile = useMediaQuery("(max-aspect-ratio: 1/1)");

    return (
        <>  <div className={"nav-bg" + isScrolled()} />
            <nav className={"nav " + isScrolled()}>
                <ul>
                    <li>
                        <NavLink to="/">
                            <img
                                src={logo_hangar}
                                alt="hangar logo"
                                width="100"
                                height="100"
                            ></img>
                        </NavLink>
                        <p className="imgtext">Hangar</p>
                    </li>
                    <li>
                        <NavLink to="/leaderboard">
                            <img
                                src={logo_leaderboard}
                                alt="leaderboard logo"
                                width="100"
                                height="100"
                            ></img>
                        </NavLink>
                        <p className="imgtext">Leaderboard</p>
                    </li>
                    <li>
                        <NavLink to="/pilot">
                            <img
                                src={logo_pilot}
                                alt="pilot logo"
                                width="100"
                                height="100"
                            ></img>
                        </NavLink>
                        <p className="imgtext">Pilot</p>
                    </li>
                </ul>
            </nav>
            {isMobile ? (
                <> </>
            ) : (<iframe className={"discord"} title="discord" src="https://discord.com/widget?id=1069310070304800842&theme=dark" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>)}
            {isMobile ? (
                <> </>
            ) : (
                <img
                    className={"logo " + isScrolled()}
                    alt="squadron logo"
                    src={logo}
                    width="300"
                    height="300"
                ></img>
            )}
            {isMobile ? (
                <> </>
            ) : (
                // patreon button
                <div className="patreon">
                    <a href="https://www.patreon.com/bePatron?u=95399910" target="_blank" rel="noopener noreferrer"><img
                        src={patreon}
                        alt="patreon logo"
                    ></img></a>
                </div>
            )}
        </>
    );
};

export default Navbar;
