import React from 'react'
import "./Background.scss"
type Props = {}

const image = require("resources/background.webp")

const Background = (props: Props) => {


    return (
        <>
            <div id="background">
                <img src={image} alt="background" />
                <div className='bg-blur' />
            </div>
        </>
    )
}

export default Background