import React from 'react'
import { motion } from "framer-motion"
import "./Hangar.scss"


type Props = {}

const Hangar = (props: Props) => {
    const logo = require("resources/viking.webp");
    const mission = require("resources/mission.webp");
    const info = require("resources/info.webp");


    return (
        <motion.div
            className='hangar'
            initial={{ x: -window.innerWidth }}
            animate={{ x: 0, transition: { duration: 0.1 } }}
            exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}>

            <div className="headertext underline">
                <h1>Welcome to the <span className="vikings"> Vikings </span> Squadron Server!</h1>
            </div>

            <div className="hero-image">
                {/* Add your hero image here */}
            </div>

            <div className="content">
                <div className="column">
                    <div className="image">
                        <img
                            alt="squadron logo"
                            src={logo}
                        ></img>
                    </div>
                    <div className="text">
                        <h2>Who are we?</h2>
                        <p><span className="vikings"> Vikings </span>Squadron is a virtual squadron consisting of new and experienced flight simmers, dedicated to providing the best mix between realism, fun and immersion for Digital Combat Simulator.
                            <br />
                            <br />
                            In addition, our statistics tracking website allows you to track your progress, compete against other pilots in our community and see your progress in real time!
                            We also host regular events and trainings, providing opportunities for our members to improve their skills, collaborate with others, and have fun.
                            <br />
                            <br />
                            We have an <a rel="noreferrer noopener" target="_blank" href="https://discord.gg/yVGcnQfDFt">active Discord Channel</a>, where we communicate while flying, provide help, and talk about aviation and other topics.
                            Our members are dedicated to providing support and guidance to all members, no matter their experience level.
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="image">
                        <img
                            alt="mission"
                            src={mission}
                        ></img>
                    </div>
                    <div className="text">
                        <h2>Mission</h2>
                        <p>
                            We have a dedicated server running 24/7, that is running a persistent dynamic mission, which is updated regularly. The mission is based on the <a rel="noreferrer noopener" target="_blank" href="https://www.digitalcombatsimulator.com/en/files/3319499/">Foothold Persistent Dynamic Sandbox</a>, and is designed to provide a fun and immersive experience for all pilots.
                            <br />
                            <br />
                            The goal of the mission is to capture areas, and defend them from enemy attacks. For Troop and Logistics Deployment, we are also running the <a rel="noreferrer noopener" target="_blank" href="https://github.com/ciribob/DCS-CTLD">DCS-CTLD</a> script.

                            There are slots for all aircraft, and the mission is designed to be played in multiplayer, with a focus on cooperation and teamwork. There is even support for modded aircraft and weapons:
                            <br />
                            <br />
                            <ul>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://github.com/Kinkkujuustovoileipa/uh-60l">UH-60L Blackhawk by Kinkkujuustovoileipa</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://forum.dcs.world/topic/252075-dcs-super-hercules-mod-by-anubis/">C-130 Hercules by Anubis</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://grinnellidesigns.com/f22/">F-22A by Grinelli</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://www.digitalcombatsimulator.com/de/files/3326837/">F-14 Advanced Air to Air Missile Mod by currrenthill & Spino</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://www.digitalcombatsimulator.com/en/files/3324876/">F-15EX Eagle II (FC3) Mod Version 1.5 by Spino</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://mega.nz/folder/zJIDACSD#oqif8uEN1N2MNwIJAJ2mPQ">FA-18 E / F / G Mod (Superbug) by Chiller Juice Studio's</a></li>
                                <li><a rel="noreferrer noopener" target="_blank" href="https://splitair.gumroad.com/l/fwzxn">Bronco-OV-10A by Split-Air</a></li>
                            </ul>
                            <br />
                            You are able to earn credits by completing missions, which can be used to call in reinforcements and a JTAC.
                            <br />
                            More info about the mission can be found on the <a rel="noreferrer noopener" target="_blank" href="https://www.digitalcombatsimulator.com/en/files/3319499/">Foothold mod page</a>, and this <a rel="noreferrer noopener" target="_blank" href="https://youtu.be/vzDlYHViREA">YouTube video</a> by Diesel Thunder.
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="image">
                        <img
                            alt="info"
                            src={info}
                        ></img>
                    </div>
                    <div className="text">
                        <h2>Info</h2>
                        <p>
                            We don't have any requirements to join our squadron, but we do have a few rules that we expect all members to follow.
                            <br />
                            You should be capable of the english language, and be able to communicate with other members. We are mostly German, but we also have members from all over the world.
                            <br />
                            Also, we expect you to be mature and respectful of others. We are a community of aviation enthusiasts, and we expect all members to be respectful of each other.
                            <br />
                            A working microphone is required, as we use Discord for voice communication. We also recommend using <a rel="noreferrer noopener" target="_blank" href="http://dcssimpleradio.com/">SRS</a>.
                            <br />
                            <br />
                            The server is running <a rel="noreferrer noopener" target="_blank" href="https://github.com/mrSkortch/DCS-SLmod">SLmod</a>, so griefing and teamkilling will result in a ban.
                        </p>
                    </div>
                </div>
            </div>


        </motion.div>
    )
}

export default Hangar