const prod = {
    url: {
     API_URL: "https://dcsstatsdataprovider.digital"
   }
};
const dev = {
url: {
    API_URL: "http://localhost:8001"
    // API_URL: "https://dcsstatsdataprovider.digital"
}
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;