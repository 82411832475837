import React, { useState } from "react"

import Spinner from 'components/spinner/Spinner';
import "./LeaderboardFlightTimeRanking.scss"

import { useNavigate } from 'react-router-dom';

import PlayerIcon from 'components/icons/PlayerIcon';
import TrophyIcon from 'components/icons/TrophyIcon';
import TimeIcon from 'components/icons/TimeIcon';

import useMediaQuery from '@mui/material/useMediaQuery';

interface rankingData {
    rank: number;
    name: string;
    flighttime: number;
    award: any
}

type Props = {
    setShowErrorBox: React.Dispatch<React.SetStateAction<number>>
    setErrorText: React.Dispatch<React.SetStateAction<string>>
    isLoading: boolean
    searchTerm: string
    data: any
}



const LeaderboardFlightTimeRanking = (props: Props) => {
    const navigate = useNavigate();

    const [sortBy, setSortBy] = useState<keyof rankingData>('rank');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const isMobile = useMediaQuery('(max-aspect-ratio: 1/1)');


    function mapToObject(arr: Array<[string, number]>): Array<rankingData> {
        return arr.map((innerArr, index) => {
            return {
                rank: index + 1,
                name: innerArr[0],
                flighttime: innerArr[1],
                award: getAward(index)
            };
        });
    }

    if (props.isLoading) {
        return <Spinner />;
    }

    function secondsToHms(d: number) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + "h " : "";
        var mDisplay = m > 0 ? m + "m " : "";
        var sDisplay = s > 0 ? s + "s " : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const handleSort = (field: keyof rankingData) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortDirection('asc');
            setSortBy(field);
        }
    };

    const imagesizes = [
        { width: "250px", height: "250px" },
        { width: "200px", height: "200px" },
        { width: "200px", height: "200px" },
        { width: "200px", height: "200px" },
    ]

    const getAward = (index: number) => {
        if (index <= 3) {
            const picture = require("resources/ranks/flighttime/" + index + ".webp")
            return (
                <img src={picture} width={imagesizes[index].width} height={imagesizes[index].height} alt={"award for rank " + index} className={"rank" + index}></img>
            )
        } else {
            return (
                <></>
            )
        }
    }

    const handleRedirect = (name: string) => {
        localStorage.setItem("searchTerm", name)
        localStorage.setItem("playerCardVisible", "True");
        navigate('/pilot');
    }

    const getSortedData = () => {
        if (props.data) {
            try {
                const sortedData = mapToObject(props.data).sort((a: rankingData, b: rankingData) => {
                    if (sortDirection === 'asc') {
                        return a[sortBy] > b[sortBy] ? 1 : -1;
                    } else {
                        return a[sortBy] < b[sortBy] ? 1 : -1;
                    }
                })

                return (sortedData.filter(val => {
                    if (props.searchTerm === '') {
                        return val
                    } else if (
                        val.name.toLowerCase().includes(props.searchTerm.toLowerCase())
                    ) {
                        return val
                    } else {
                        return undefined
                    }
                }).map((item: rankingData, index: number) => (
                    <tbody key={"body" + index}>
                        <tr onClick={() => handleRedirect(item.name)} key={"tr" + index} className={"trow rank-" + index}>
                            <td key={"rank" + index} className={"tableitem_rank rank" + item.rank}>
                                <div className={"rankdiv"}>
                                    <div className={"rankdivshape rank" + item.rank}>
                                    </div>
                                    <p className={"rank" + item.rank}>{item.rank}</p>
                                </div>
                            </td>
                            <td key={"name" + index} className={"tableitem_name rank" + item.rank}>
                                <p>{item.name}</p>
                            </td>
                            <td key={"time" + index} className={"tableitem_time rank" + item.rank}>
                                <p>{secondsToHms(item.flighttime)}</p>
                            </td>
                            {isMobile ? <></> : <td key={"award" + index} className={"tableitem_award rank" + item.rank}>{item.award}</td>}
                        </tr>
                    </tbody>

                )
                ))
            } catch (err: any) {
                props.setShowErrorBox(1)
                props.setErrorText(err.message)
            }

        }
    }



    return (
        <div className="flighttimerankingscontent">

            <div className="flighttimerankingstablecontainer">

                <table className="blur smoothboxshadow">
                    <tbody>
                        <tr >
                            <th onClick={() => handleSort('rank')}><TrophyIcon />  Rank {sortBy === 'rank' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('name')}><PlayerIcon />  Name {sortBy === 'name' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            <th onClick={() => handleSort('flighttime')}><TimeIcon />  Flight Time {sortBy === 'flighttime' && (sortDirection === 'asc' ? <span>↑</span> : <span>↓</span>)}</th>
                            {isMobile ? <></> : <th onClick={() => handleSort('award')}></th>}
                        </tr>
                    </tbody>
                    {getSortedData()}
                </table>
            </div>
        </div>
    );
};

export default LeaderboardFlightTimeRanking