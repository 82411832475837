import React from 'react'
import "./Icon.scss"

const icon = require("resources/icons/trophy.png")

type Props = {
}

const TrophyIcon = (props: Props) => {
  return (
    <img src={icon} className="icon" height="20px" width="20px" alt="trophy icon"></img>
  )
}

export default TrophyIcon