import React from 'react'
import "./ErrorBox.scss"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type Props = {
  errorText: string
}

const ErrorBox = (props: Props) => {
  return (
    <div className='errorbox'>

      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.errorText}
      </Alert>
    </div>
  )
}

export default ErrorBox