import React, { useState } from 'react'
import { motion } from "framer-motion"
import ErrorBox from 'components/error/ErrorBox';
import axios, { AxiosError } from "axios";
import { useQuery } from 'react-query'
import "./Leaderboard.scss"
import 'react-json-pretty/themes/monikai.css';
import LeaderboardFlightTimeRanking from './LeaderboardFlightTimeRanking/LeaderboardFlightTimeRanking';
import LeaderboardTotalPointsRanking from './LeaderboardTotalPointsRanking/LeaderboardTotalPointsRanking';
import LeaderboardAllPlayerStats from './LeaderBoardAllPlayerStats/LeaderboardAllPlayerStats';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { config } from "env_constants";
import { getAnalytics, logEvent } from "firebase/analytics";

import playerRankingByFlightTimeJson from "../../assets/playerrankingbyflighttime.json"
import playerRankingByPointsJson from "../../assets/playerrankingbypoints.json"
import allPlayerStats from "../../assets/allplayerstats.json"

type Props = {}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const Leaderboard = (props: Props) => {
    const [showErrorBox, setShowErrorBox] = useState(0)
    const [errorText, setErrorText] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [value, setValue] = useState(0);
    const analytics = getAnalytics();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const backendURL = config.url.API_URL

    const fetchedresults = {
        rankingbyflighttime: {
            isLoading: false,
            data: playerRankingByFlightTimeJson["flighttimeranking"]
        },
        rankingbypoints: {
            isLoading: false,
            data: playerRankingByPointsJson["pointranking"]
        },
        allplayerstats: {
            isLoading: false,
            data: allPlayerStats["allplayerstats"]
        },
        lastUpdated: {
            isLoading: false,
            data: "2022-09-01"
        }

    }

    const getErrorBox = () => {
        if (showErrorBox && errorText) {
            return (
                <ErrorBox errorText={errorText}></ErrorBox>
            )
        }
    }


    function TabPanel(props: any) {
        const { value, index, children } = props;
        return (
            <div hidden={value !== index}>
                {children}
            </div>
        );
    }

    return (

        <motion.div
            className='leaderboard'
            initial={{ x: -window.innerWidth }}
            animate={{ x: 0, transition: { duration: 0.1 } }}
            exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}>
            <div className="searchfield">

                <TextField onChange={(e) => { setSearchTerm(e.target.value) }} id="standard-basic" label="Search" variant="standard" />
            </div>
            <Box sx={{ width: '100%' }}>
                <Box className="categoryBox" sx={{ borderBottom: 0, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="All Stats" {...a11yProps(0)} />
                        <Tab label="Flight Time" {...a11yProps(1)} />
                        <Tab label="Points" {...a11yProps(2)} />
                    </Tabs>
                    <div className="lastUpdated">
                        <p>Last Updated: {fetchedresults.lastUpdated.data}</p>
                    </div>
                </Box>

                <TabPanel value={value} index={0}>
                    <LeaderboardAllPlayerStats setShowErrorBox={setShowErrorBox} setErrorText={setErrorText} data={fetchedresults.allplayerstats.data} isLoading={fetchedresults.allplayerstats.isLoading} searchTerm={searchTerm} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <LeaderboardFlightTimeRanking setShowErrorBox={setShowErrorBox} setErrorText={setErrorText} data={fetchedresults.rankingbyflighttime.data} isLoading={fetchedresults.rankingbyflighttime.isLoading} searchTerm={searchTerm} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <LeaderboardTotalPointsRanking setShowErrorBox={setShowErrorBox} setErrorText={setErrorText} data={fetchedresults.rankingbypoints.data} isLoading={fetchedresults.rankingbypoints.isLoading} searchTerm={searchTerm} />
                </TabPanel>
                {getErrorBox()}
            </Box>


        </motion.div >
    )
}


export default Leaderboard